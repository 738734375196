import { useCallback, useState } from 'react';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import './../styles/Login.scss';
import Lottie from 'react-lottie';
import animationData from '../lottie/drink.json';
import Logo from '../images/bargoat-app-icon.png';
import { useAuth } from '../contexts/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();

  const navigate = useNavigate();
  const { login, signup } = useAuth();

  const handleSubmit = useCallback(
    async (event: any, isSignup: boolean) => {
      event.preventDefault();

      if (isSignup) {
        await signup(username, password).then(() => {
          navigate('/app');
        });
      } else {
        await login(username, password).then(() => {
          window.localStorage.removeItem('selectedDate');
          navigate(location.state?.from?.pathname || '/app');
        });
      }
    },
    [username, password, login, signup, navigate, location]
  );

  const validateForm = useCallback(() => {
    return username.length > 0 && password.length > 0;
  }, [username, password]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container fluid>
      <Row>
        <Col lg="6" className="split-left d-flex align-items-center">
          <div className="col-lg-7" id="form-login">
            <h2 className="form-heading">
              SIGN IN TO BARGOAT{' '}
              <img src={Logo} alt="BarGoat" style={{ maxWidth: '36px' }} className="ml-1" />
            </h2>
            <hr className="form-split mt-2 mb-4"></hr>
            <Form>
              <FormGroup controlId="username">
                <FormLabel className="form-label">Username</FormLabel>
                <FormControl
                  autoFocus
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="login-input"
                  placeholder="Username"
                />
              </FormGroup>
              <FormGroup controlId="password">
                <FormLabel className="form-label">Password</FormLabel>
                <FormLabel className="float-right forgot">
                  <Link to="/forgotPassword">Forgot password?</Link>
                </FormLabel>
                <FormControl
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  className="login-input"
                  placeholder="Password"
                />
              </FormGroup>
              <Button
                disabled={!validateForm()}
                type="submit"
                onClick={(e: any) => handleSubmit(e, false)}
                className="login-btn col-lg-4 mt-2"
              >
                Sign In
              </Button>{' '}
            </Form>
            <hr className="form-split mt-4 mb-3"></hr>
            <p className="help-text">
              {"Don't have an account? "}
              <Link to="/#">Contact sales</Link>
            </p>
          </div>
        </Col>
        <Col lg="6" className="split-right d-none d-lg-block">
          <div className="align-items-center" style={{ marginTop: '100px' }}>
            <Lottie options={defaultOptions} height={500} width={500} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
