import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { modifyReservation } from '../api';

class DeleteModal extends React.Component<any, any> {
  state = {
    show: false,
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if (this.props.updateReservation) {
      this.props
        .updateReservation({ _id: this.props.reservation._id, isDeleted: true })
        .then((res: any) => {
          this.handleClose();
        })
        .catch((err: any) => console.log(err));
    }
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  public render() {
    return (
      <Modal show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Reservation</Modal.Title>
        </Modal.Header>

        <Modal.Body>Are you sure you want to delete the reservation?</Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={this.handleSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteModal;
