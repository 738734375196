export const convertMinToTime = (total) => {
  const H = Math.floor(total / 60);
  const h = H % 12 || 12;
  const ampm = H < 12 ? 'AM' : 'PM';
  const m = total % 60;
  const mm = m < 10 ? `0${m}` : m;
  return `${h}:${mm} ${ampm}`;
};

export const convertMinTo24Hour = (total) => {
  const H = Math.floor(total / 60);
  const HH = H < 10 ? `0${H}` : H;
  const m = total % 60;
  const mm = m < 10 ? `0${m}` : m;
  return `${HH}:${mm}`;
};

export const convert24HourToMin = (HHmm) => {
  const [HH, mm] = HHmm.split(':');
  return parseInt(HH) * 60 + parseInt(mm);
};

export const toDateString = (date) => {
  let isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  return isoDateTime.split('T')[0];
};
