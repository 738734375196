import { useEffect, useState } from 'react';
import './../styles/Menu.scss';
import { checkIn } from '../api';
import SuccessImg from '../images/success.svg';
import ErrorImg from '../images/error.svg';
import { useParams } from 'react-router-dom';

const CheckIn = () => {
  const [image, setImage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const { reservationId } = useParams();

  useEffect(() => {
    checkIn(reservationId)
      .then(() => {
        setImage(SuccessImg);
      })
      .catch((err: Error) => {
        setImage(ErrorImg);
        setErrorMsg(err.message);
      });
  }, [reservationId]);

  return (
    <div>
      <div className="text-center d-flex justify-content-center img-fluid h-100 w-100 checkin-bg">
        <img src={image} />
      </div>
      <h5 className="text-center d-flex justify-content-center p-4">{errorMsg}</h5>
    </div>
  );
};

export default CheckIn;
