import React from 'react';

interface AppContextProps {
  selectedDate: Date;
  handleChangeDate: (date: Date) => void;
  handleChangeQuery: (query: string) => void;
}

const appContextProps: AppContextProps = {
  selectedDate: new Date(),
  handleChangeDate: () => void 0,
  handleChangeQuery: () => void 0,
};

const AppContext = React.createContext(appContextProps);

export default AppContext;
