import Cookies from 'universal-cookie';

export const authHeader = (options) => {
  // return authorization header with basic auth credentials
  const cookies = new Cookies();
  let tk = cookies.get('tk');
  // if (user && user.token) {
  // return { Authorization: `Bearer ${user.token}` };
  if (!options.headers) {
    options.headers = {};
  }
  if (tk) {
    options.headers.Authorization = tk;
  }
};
