import { useCallback, useContext, useState } from 'react';
import {
  Col,
  Row,
  Navbar,
  Form,
  FormControl,
  InputGroup,
  Nav,
  NavDropdown,
  Breadcrumb,
  BreadcrumbItem,
} from 'react-bootstrap';
import {
  FaSearch,
  FaUser,
  FaCogs,
  FaInfoCircle,
  FaSignOutAlt,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import AppContext from '../contexts/AppContext';
import DatePicker from 'react-datepicker';
import './../styles/Menu.scss';
import BargoatAppIcon from '../images/bargoat-app-icon.png';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function Menu({
  selectedDate,
  handleChangeDate,
  businessName,
  selectedBarId,
  barsByBusiness,
  handleChangeBar,
}: any) {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const [query, setQuery] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const onSubmitForm = (event: any) => {
    event.preventDefault(); // prevent form refresh page
  };

  const onChangeQuery = useCallback(
    (event: any) => {
      const _query = event.target.value;
      setQuery(_query);
      appContext.handleChangeQuery(_query);
    },
    [appContext, setQuery]
  );

  return (
    <div className="sticky-top">
      <Navbar expand="lg">
        <img src={BargoatAppIcon} alt="BarGoat" className="navbar-logo" />
        <Col lg="9" className="hidden-mobile">
          <Form inline className="align-middle" onSubmit={onSubmitForm}>
            <InputGroup className="mb-2 mr-sm-2" style={{ width: '100%' }}>
              <InputGroup.Prepend>
                <InputGroup.Text className="prepend">
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                size="sm"
                type="text"
                placeholder="Search all guestlists &amp; reservations..."
                className="search"
                value={query}
                onChange={onChangeQuery}
              />
            </InputGroup>
          </Form>
        </Col>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <FaRegCalendarAlt />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <DatePicker
            selected={selectedDate}
            onChange={handleChangeDate}
            className="p-1 text-center date-picker"
            wrapperClassName="datepicker"
          />
        </InputGroup>

        <div className="hidden-mobile">
          <div>
            <Nav>
              <NavDropdown title={businessName} id="basic-nav-dropdown" alignRight>
                <NavDropdown.Item
                  onClick={() => navigate(`/app/bar/${selectedBarId}/accountsettings`)}
                >
                  <FaUser className="mr-2" /> Profile
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate(`/app/bar/${selectedBarId}/barsettings`)}>
                  <FaCogs className="mr-2" /> Settings
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate('/help')}>
                  <FaInfoCircle className="mr-2" /> Help
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>
                  <FaSignOutAlt className="mr-2" /> Logout
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </div>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={() => navigate('/')}>Dashboard</Nav.Link>
            <Nav.Link onClick={() => navigate(`/app/bar/${selectedBarId}/botsettings`)}>
              Bot Settings
            </Nav.Link>
            <Nav.Link onClick={() => navigate(`/app/bar/${selectedBarId}/queue`)}>Queue</Nav.Link>
            <NavDropdown title="Enterprise Entertainment" id="collasible-nav-dropdown">
              {barsByBusiness.map((x: any) => (
                <NavDropdown.Item
                  onClick={() => navigate(`/app/bar/${x._id}`)}
                  // onClick={() => handleChangeBar(x._id)}
                  key={x._id}
                >
                  {x.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link onClick={logout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Breadcrumb className="barSelector align-items-center hidden-mobile">
        <span className="breadcrumb-title">Venues:</span>
        {barsByBusiness.map((x: any) => (
          <Breadcrumb.Item onClick={() => navigate(`/app/bar/${x._id}`)} key={x._id}>
            {x.name}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default Menu;
