import React from 'react';
import '../styles/Tag.scss';

class Tag extends React.Component<any> {
  public render() {
    return (
      <div className="tag-container" style={{ backgroundColor: this.props.color }}>
        {this.props.text}
      </div>
    );
  }
}

export default Tag;
