import { useState } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { get2Fa } from '../api';

const TwoFactor = () => {
  const [code, setCode] = useState('----');

  const handleClick = () => {
    get2Fa()
      .then((res: any) => {
        setCode(res.token != null ? res.token : 'error...');
      })
      .catch((err: any) => {
        alert(err);
      });
  };

  return (
    <Container>
      <Row>
        <Button onClick={handleClick}>Fetch Code</Button>
      </Row>
      <Row>
        <div>Current 2FA Code:</div>
        <div>{code}</div>
      </Row>
    </Container>
  );
};

export default TwoFactor;
