import { authHeader } from './utils/authHeader';
import moment from 'moment';

const _objToQueryString = (obj) => {
  const keyValuePairs = [];
  for (const key in obj) {
    keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
  }
  return keyValuePairs.join('&');
};

const _handleError = (status, body) => {
  if (status === 401 && window.location.href.indexOf('check-in') < 0) {
    window.location.href = '../login';
  }

  throw Error(body.message);
};

export const ENDPOINT =
  // eslint-disable-next-line no-undef
  process.env.REACT_APP_API_ENV === 'local'
    ? 'http://localhost:5050'
    : 'https://app.bargoat.com:5050';

const url = `${ENDPOINT}/api`;

export const getReservations = async (query) => {
  const options = {};
  authHeader(options);
  const eventdate = moment(query.date, 'YYYY-MM-DD');
  const today = moment().format('YYYY-MM-DD');
  if (eventdate < today) {
    query.past_reservations = true;
  }
  const queryString = _objToQueryString(query);
  const response = await fetch(url + `/reservation?${queryString}`, options);
  const body = response.status === 200 ? response.json() : response.statusText;
  if (response.status !== 200) return _handleError(response.status, body);
  return body;
};

export const createReservation = async (reservation) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ ...reservation, tags: [] }),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  authHeader(options);

  const response = await fetch(url + `/reservation`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status, body);

  return body;
};

export const checkIn = async (reservationid) => {
  const options = {
    method: 'POST',
    //body: JSON.stringify(reservation),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  authHeader(options);

  const response = await fetch(
    url + `/reservation/updatePresentReservation/` + reservationid,
    options
  );
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status, body);
  console.log("''''''''''", response, body);
  return body;
};

export const modifyReservation = async (id, reservation) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(reservation),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  authHeader(options);

  const response = await fetch(url + `/reservation/${id}`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const getBusiness = async (id) => {
  const options = {};
  authHeader(options);

  const response = await fetch(url + `/business_entity/${id}`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const getBarsByBusiness = async (business_entity_id) => {
  const options = {};
  authHeader(options);

  const response = await fetch(url + `/bar/business_entity_id/${business_entity_id}`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const getBar = async (id) => {
  const options = {};
  authHeader(options);

  const response = await fetch(url + `/bar/${id}`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const modifyBar = async (id, bar) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(bar),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  authHeader(options);

  const response = await fetch(url + `/bar/${id}`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

// AUTHENTICATION
export const login = async (user) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(user),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url + `/user/login`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const signup = async (user) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(user),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(url + `/user/signup`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const getUser = async (token) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  authHeader(options);

  const response = await fetch(url + `/user/me`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const forgotPassword = async (email) => {
  const options = {
    method: 'POST',
    body: JSON.stringify(email),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url + `/user/forgotPassword`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const checkResetToken = async (resetPasswordToken) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(
    url + `/user/checkResetToken?resetPasswordToken=${resetPasswordToken}`,
    options
  );
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const resetPassword = async (username, password) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({
      username,
      password,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url + `/user/resetPassword`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

// USER
export const modifyUser = async (token, user) => {
  const options = {
    method: 'PATCH',
    body: JSON.stringify(user),
    headers: {
      'Content-Type': 'application/json',
    },
  };
  authHeader(options);

  const response = await fetch(url + `/user`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

// Push Notification
export const sendPushNotification = async (senderId, message) => {
  const fbToken =
    'EAASzLCLUnkcBAF2uWi2aW6vvbaOrulPGOXnOxIwDXsZBXsk61WfMlGmtecDXAKWHzfM2mZCbxxpOZCm9dL3mcfZAESZAcNZCgU0wYbhZB8osIlByDxrrDviZC31Eflo18eXm588Bv8JYyq7NSGWxqOXKYXv8HDgsnZCWjDyf9IYuAwwZDZD';

  const options = {
    method: 'post',
    body: JSON.stringify({
      messaging_type: 'MESSAGE_TAG',
      tag: 'CONFIRMED_EVENT_UPDATE',
      recipient: {
        id: senderId,
      },
      message,
    }),
    headers: { 'Content-Type': 'application/json' },
  };
  const response = await fetch(
    'https://graph.facebook.com/v7.0/me/messages?access_token=' + fbToken,
    options
  );

  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);
  return body;
};

// QR Code
export const scan = async (ticketToken) => {
  const options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url + `/reservation/scan?ticketToken=${ticketToken}`, options);
  const body = await response.json();
  if (response.status !== 200) return _handleError(response.status);

  return body;
};

export const get2Fa = async () => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url + '/fb_user/2fa', options);
  const body = await response.json();
  return body;
};
