import React from 'react';
import { Container, Row, Col, DropdownButton, Dropdown } from 'react-bootstrap';
import ListItem from './ListItem';
import './../styles/List.scss';

class List extends React.Component<any, any> {
  static defaultProps = {
    updateReservation: () => void 0,
  };

  sort(sortBy: string) {
    let result: any;
    switch (sortBy) {
      case 'name':
        result = this.props.reservations.sort((a: any, b: any) => {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
        break;
      case 'guests':
        result = this.props.reservations.sort((a: any, b: any) => a.guests - b.guests);
        break;
    }
    this.setState({ reservations: result });
  }

  public render() {
    const rows: any = [];
    this.props.reservations.forEach((reservation: any) => {
      rows.push(
        <ListItem
          key={reservation._id}
          reservation={reservation}
          handleSelection={this.props.handleSelection}
          updateReservation={this.props.updateReservation}
          moveToTagged={this.props.moveToTagged}
          moveFromTagged={this.props.moveFromTagged}
          name={this.props.name}
        ></ListItem>
      );
    });
    return (
      <Container className="mt-5">
        <Row>
          <Col lg="8" className="text-left list-name">
            {this.props.name}
          </Col>
          <Col lg="4">
            <DropdownButton variant="outline-primary" id="sort-button" title="Sort By" size="sm">
              <Dropdown.Item onClick={() => this.sort('name')}>Name (A-Z)</Dropdown.Item>
              <Dropdown.Item onClick={() => this.sort('guests')}>
                Guests (Low to High)
              </Dropdown.Item>
            </DropdownButton>
          </Col>
        </Row>
        <hr></hr>
        <Container className="List-Container">{rows}</Container>
      </Container>
    );
  }
}

export default List;
