import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { modifyReservation, scan } from '../api';

const Scanner = () => {
  const [reservation, setReservation] = useState<any>(null);
  const [error, setError] = useState(false);
  const params: any = useParams();

  useEffect(() => {
    scan(params.ticketToken).then((res: any) => {
      if (res.status === 200) {
        setReservation(res);
      } else {
        setError(true);
      }
    });
  }, []);

  return (
    <Container>
      {error ? (
        <div>Error occured</div>
      ) : reservation ? (
        <div>
          <div>Reservation Confirmed!</div>
          <div>Name: {reservation.name}</div>
          <div>Date: {reservation.date}</div>
        </div>
      ) : null}
    </Container>
  );
};

export default Scanner;
