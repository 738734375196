import { Container, Row, Col } from 'react-bootstrap';
import Menu from './Menu';
import Sidebar from './Sidebar';
import Lottie from 'react-lottie';
import animationData from '../lottie/loading.json';
import './../styles/App.scss';
import Dashboard from './Dashboard';
import { Route, Routes } from 'react-router-dom';
import withData from '../HOCs/withData';
import PrivateRouteBarWrapper from './PrivateRouteBarWrapper';

const RouteWrapper = (props: any) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container className="no-padding-x" fluid>
      <Row noGutters>
        <div id="sidebar-wrapper">
          <Sidebar {...props}></Sidebar>
        </div>
        <Col id="page-content-wrapper">
          <Menu {...props} handleChangeBar={props.handleChangeBar} />
          {props.loading ? (
            <div>
              <Lottie options={defaultOptions} height={100} width={100} />
            </div>
          ) : (
            <Routes>
              <Route path={`/`} element={<Dashboard {...props} />} />
              <Route path={`bar/`} element={<Dashboard {...props} />} />
              <Route path={`bar/:barId/*`} element={<PrivateRouteBarWrapper {...props} />} />
            </Routes>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default withData(RouteWrapper);
