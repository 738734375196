import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { checkResetToken, resetPassword } from '../api';
import Logo from '../images/bargoat-app-icon.png';
import Lottie from 'react-lottie';
import animationData from '../lottie/drink.json';

const ResetPassword = () => {
  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState(false);

  const params: any = useParams();

  const handleResetPassword = useCallback(
    (e: any) => {
      e.preventDefault();

      resetPassword(username, password)
        .then((res: any) => {
          setIsSent(true);
        })
        .catch((err: any) => {
          alert(err);
        });
    },
    [username, password]
  );

  const validateForm = useMemo(() => {
    return password.length > 3 && password === repassword;
  }, [password, repassword]);

  useEffect(() => {
    checkResetToken(params.token)
      .then((res: { message: string; username: string }) => {
        if (res.message == 'password reset link a-ok') {
          setUsername(res.username);
        } else {
          setError(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
        setError(true);
      });
  }, [params, setError]);

  if (error) {
    return <div>Expired link.</div>;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container fluid>
      <Row>
        <Col lg="6" className="split-left d-flex align-items-center">
          <div className="col-lg-7" id="form-login">
            <h2 className="form-heading">
              RESET YOUR PASSWORD{' '}
              <img src={Logo} alt="BarGoat" style={{ maxWidth: '36px' }} className="ml-1" />
            </h2>
            <hr className="form-split mt-2 mb-4"></hr>
            {isSent ? (
              <div>Password has been reset.</div>
            ) : (
              <Form>
                <FormGroup controlId="password">
                  <FormLabel>Password</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  ></FormControl>
                </FormGroup>
                <FormGroup controlId="repassword">
                  <FormLabel>Retype Password</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={repassword}
                    onChange={(e) => setRepassword(e.target.value)}
                    placeholder="Retype password"
                  ></FormControl>
                </FormGroup>

                <Button disabled={!validateForm} type="submit" onClick={handleResetPassword}>
                  Send
                </Button>
              </Form>
            )}
            <hr className="form-split mt-4 mb-3"></hr>
            <p className="help-text">
              Need assistance? <Link to="/#">Contact support</Link>
            </p>
          </div>
        </Col>
        <Col lg="6" className="split-right d-none d-lg-block">
          <div className="align-items-center" style={{ marginTop: '100px' }}>
            <Lottie options={defaultOptions} height={500} width={500} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
