import React from 'react';
import { FaCheck, FaEllipsisV } from 'react-icons/fa';
import { Container, Row, Col, Dropdown, Modal } from 'react-bootstrap';
import styles from './../styles/styles.module.scss';
import './../styles/ListItem.scss';
import { modifyReservation } from './../api';
import Tag from './Tag';
import EditorModal from './EditorModal';
import DeleteModal from './DeleteModal';
import QRCode from 'qrcode';
import { Link } from 'react-router-dom';

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <Link
    to="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* custom icon */}
    <FaEllipsisV />
    {children}
  </Link>
));
CustomToggle.displayName = 'CustomToggle';

class ListItem extends React.Component<any> {
  state = {
    onHover: false,
    qrCode: '',
    showQRCode: false,
    clicked: this.props.handleSelection ? false : this.props.reservation.isPresent,
    reservation: this.props.reservation,
  };

  private editorModalRef: any;
  private deleteModalRef: any;
  constructor(props: any) {
    super(props);
    this.editorModalRef = React.createRef();
    this.deleteModalRef = React.createRef();
  }

  setOnHover(onHover: boolean) {
    this.setState({ onHover });
  }

  handleOnClick = (reservation: any) => {
    this.setState({ clicked: !this.state.clicked });
    if (this.props.handleSelection) this.props.handleSelection(reservation);
    else this.handleIsPresent(reservation);
  };

  handleIsPresent = async (reservation: any) => {
    reservation.isPresent = !reservation.isPresent;
    this.setState({ reservation });
    this.props.updateReservation(reservation);
  };

  handleMoveToTagged = async () => {
    this.props.moveToTagged(this.state.reservation);
  };

  handleMoveFromTagged = async () => {
    this.props.moveFromTagged(this.state.reservation);
  };

  handleEdit = () => {
    this.editorModalRef.current.handleShow();
  };

  showBarcode = (id: string) => {
    QRCode.toDataURL('http://app.bargoat.com/check-in/' + id, (err, url) => {
      if (err) return console.log(err);
      this.setState({ qrCode: url });
      // setQrcode(url);
      this.setState({ showQRCode: true });
    });
  };

  handleClose = () => {
    this.setState({ showQRCode: false });
  };

  handleDelete = () => {
    this.deleteModalRef.current.handleShow();
  };

  public render() {
    const clickedColor = this.state.clicked ? styles.baseGreen : styles.baseOrange;

    return (
      <Container className="ListItem">
        <Row className="ListItem-Row">
          <Col
            lg="2"
            className="ListItem-Col-1"
            style={{ backgroundColor: clickedColor }}
            onMouseEnter={() => this.setOnHover(true)}
            onMouseLeave={() => this.setOnHover(false)}
            onClick={() => this.handleOnClick(this.state.reservation)}
          >
            {this.state.onHover ? (
              <div>
                <FaCheck />
              </div>
            ) : (
              <div>+{this.state.reservation.guests}</div>
            )}
          </Col>
          <Col className="ListItem-Col-2">{this.state.reservation.name}</Col>
          {this.props.reservation.isPushed && (
            <Col lg="3" className="ListItem-Col-2">
              <Tag text="PUSHED" color="green" />
            </Col>
          )}
          <Col lg="2" className="ListItem-Col-3">
            <Dropdown>
              <Dropdown.Toggle id="dropdown" as={CustomToggle}></Dropdown.Toggle>
              <Dropdown.Menu title="" className="item-dropdown">
                {/* <Dropdown.Item onClick={this.handleMove}>Move</Dropdown.Item> */}
                <Dropdown.Item onClick={() => this.showBarcode(this.state.reservation._id)}>
                  QR Code
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleEdit}>Edit</Dropdown.Item>
                {this.props.name.toLowerCase().indexOf('tagged') < 0 &&
                  this.props.name.toLowerCase().indexOf('manager') < 0 && (
                    <Dropdown.Item onClick={this.handleMoveToTagged.bind(this.state.reservation)}>
                      Add to Tagged
                    </Dropdown.Item>
                  )}
                {this.props.name.toLowerCase().indexOf('tagged') >= 0 && (
                  <Dropdown.Item onClick={this.handleMoveFromTagged.bind(this.state.reservation)}>
                    Remove from Tagged
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item className="delete" onClick={this.handleDelete}>
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <Modal animation={false} show={this.state.showQRCode} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Check In QR Code</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <img src={this.state.qrCode} />
          </Modal.Body>
        </Modal>
        <EditorModal ref={this.editorModalRef} reservation={this.state.reservation} />
        <DeleteModal
          ref={this.deleteModalRef}
          reservation={this.state.reservation}
          updateReservation={this.props.updateReservation}
        />
      </Container>
    );
  }
}

export default ListItem;
