import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'universal-cookie';
import { login as loginApi, signup as signupApi } from '../api';

const cookies = new Cookies();
const cookieOptions = {
  path: '/',
  httpOnly: false,
  maxAge: 60 * 60 * 24 * 7,
};

const AuthContext = createContext({
  isAuthenticated: false,
  login: (username: string, password: string) => new Promise(() => void 0),
  signup: (username: string, password: string) => new Promise(() => void 0),
  logout: () => {
    // left intentionally empty...
  },
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    JSON.parse(localStorage.getItem('isAuthenticated') || 'false')
  );

  // Update localStorage when isAuthenticated changes
  useEffect(() => {
    localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  const isTokenExpired = useCallback((token: string) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // get current time in seconds
      return decoded.exp ? decoded.exp < currentTime : true;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return true; // Assume the token is invalid/expired on error
    }
  }, []);

  useEffect(() => {
    const token = cookies.get('tk');
    if (token && !isTokenExpired(token)) {
      setIsAuthenticated(true);
    } else {
      localStorage.removeItem('tk');
      setIsAuthenticated(false);
    }
  }, [isTokenExpired]);

  const login = useCallback(async (username: string, password: string) => {
    await loginApi({ username, password })
      .then((res: any) => {
        window.localStorage.removeItem('selectedDate');
        cookies.set('username', res.username, cookieOptions);
        cookies.set('business_id', res.business_entity_id, cookieOptions);
        cookies.set('tk', res.token, cookieOptions);
        setIsAuthenticated(true);
      })
      .catch((err: any) => {
        console.log(err);
        alert('Incorrect username and password');
      });
  }, []);

  const signup = useCallback(async (username: string, password: string) => {
    await signupApi({ username, password })
      .then((res: any) => {
        window.localStorage.removeItem('selectedDate');
        cookies.set('username', res.data, cookieOptions);
        cookies.set('name', res.data, cookieOptions);
      })
      .catch((err: any) => {
        console.log(err);
        alert(
          'Invalid Input:\nusername must be at least 3 characters\npassword must be at least 6 characters'
        );
      });
  }, []);

  const logout = useCallback(() => {
    cookies.remove('username');
    cookies.remove('business_id');
    cookies.remove('tk');
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, signup, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
