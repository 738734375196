import React from 'react';
import { Button, Nav } from 'react-bootstrap';
import { FaHome, FaRobot, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './../styles/Menu.scss';
import EditorModal from './EditorModal';
import BargoatAppIcon from '../images/bargoat-app-icon.png';

class Sidebar extends React.Component<any, any> {
  private editorModalRef: any;
  constructor(props: any) {
    super(props);
    this.editorModalRef = React.createRef();
  }

  onManagerModalClick = () => {
    this.editorModalRef.current.handleShow();
  };

  render() {
    return (
      <div className="sticky-top">
        <Nav className="justify-content-center">
          <Nav.Item>
            <Link to={`/app/bar/${this.props.selectedBarId}`}>
              <img src={BargoatAppIcon} alt="BarGoat" className="sidebar-logo" />
            </Link>
          </Nav.Item>
        </Nav>
        <Nav className="col-md-12 d-none d-md-block sidebar-nav icon-nav">
          <div className="sidebar-sticky"></div>
          <Nav.Item>
            <Link to={`/app/bar/${this.props.selectedBarId}`} className="mt-4 p-2 active">
              <FaHome />
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={`/app/bar/${this.props.selectedBarId}/botsettings`} className="mt-4 p-2 mb-4">
              <FaRobot />
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to={`/app/bar/${this.props.selectedBarId}/queue`} className="p-2">
              <FaUserFriends />
            </Link>
          </Nav.Item>
        </Nav>
        <Button
          style={{ position: 'fixed', bottom: 0 }}
          onClick={this.onManagerModalClick}
          className="add-list-button rounded-circle"
        >
          +
        </Button>
        <EditorModal ref={this.editorModalRef} makeReservation={this.props.makeReservation} />
      </div>
    );
  }
}

export default Sidebar;
