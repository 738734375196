import { useState } from 'react';
import './../styles/App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './Login';
import PrivateRouteWrapper from './PrivateRouteWrapper';
import AppContext from '../contexts/AppContext';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import QRCode from './QRCode';
import Scanner from './Scanner';
import CheckIn from './CheckIn';
import { AuthProvider } from '../contexts/AuthContext';
import { RequireAuth } from './RequireAuth';
import TwoFactor from './TwoFactor';

function App() {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleChangeDate = (date: any) => {
    setSelectedDate(date);
  };

  return (
    <div className="App">
      <AuthProvider>
        <AppContext.Provider
          value={{
            selectedDate: selectedDate,
            handleChangeDate: handleChangeDate,
            handleChangeQuery: () => void 0,
          }}
        >
          <BrowserRouter>
            <Routes>
              {/* Public Access Routes */}
              <Route path={'/'} element={<Login />} />
              <Route path={'/login'} element={<Login />} />
              <Route path={'/forgotPassword'} element={<ForgotPassword />} />
              <Route path="/resetPassword/:token" element={<ResetPassword />} />
              <Route path="/qrcode" element={<QRCode />} />
              <Route path="/2fa" element={<TwoFactor />} />

              {/* Private Access Routes */}
              <Route
                path="/app/*"
                element={
                  <RequireAuth>
                    <PrivateRouteWrapper />
                  </RequireAuth>
                }
              />
              <Route
                path={'/check-in/:reservationId'}
                element={
                  <RequireAuth>
                    <CheckIn />
                  </RequireAuth>
                }
              />
              <Route path={'/scan/:ticketToken'} element={<Scanner />} />
            </Routes>
          </BrowserRouter>
          <ToastContainer />

          {/* <button onClick={() => toast("Wow so easy")}>Push Toast</button> */}
        </AppContext.Provider>
      </AuthProvider>
    </div>
  );
}

export default App;
