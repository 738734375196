import React, { useState } from 'react';
import { Container, Form, Button, Col } from 'react-bootstrap';
import { convert24HourToMin, convertMinTo24Hour } from '../utils/timeConverter';
import TimePicker, { TimePickerValue } from 'react-time-picker';
import './../styles/BotSettings.scss';
import { toast } from 'react-toastify';

function BotSettings({
  guestlistCutoffTime,
  guestlistCapacityMax,
  maxListSizeSubmission,
  updateBar,
  barsByBusiness,
  selectedBarId,
}: any) {
  const [cutoffTime, setCutoffTime] = useState<TimePickerValue>(
    convertMinTo24Hour(guestlistCutoffTime)
  );
  const [capacity, setCapacity] = useState(guestlistCapacityMax);
  const [maxListSize, setMaxListSize] = useState(maxListSizeSubmission);
  const [hasBoothBooking, setHasBoothBooking] = useState('No');
  const barName = barsByBusiness.find((bar: any) => bar._id === selectedBarId)?.name;
  const handleSubmit = (event: any) => {
    event.preventDefault();

    if (!cutoffTime) {
      return toast.error('Save Failed: Missing Cutoff Time.');
    } else if (capacity <= 0) {
      return toast.error('Save Failed: Capacity must be greater than 0.');
    } else if (maxListSize <= 0) {
      return toast.error('Save Failed: Max List Size Submission must be greater than 0.');
    }

    const body = {
      rules: {
        cutoffTime: convert24HourToMin(cutoffTime),
        capacity,
        maxListSizeSubmission: maxListSize,
        hasBoothBooking: hasBoothBooking === 'Yes' ? true : false,
      },
    };
    updateBar(body);
  };

  return (
    <div>
      <Container className="full-height">
        <Col lg={5}>
          <h2 className="status-title mt-4">Bot Settings: {barName}</h2>
          <hr className="hr-break mt-3"></hr>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="control-0">
              <Form.Label>Cutoff Time</Form.Label>
              <div>
                <TimePicker
                  onChange={(newTime) => setCutoffTime(newTime)}
                  value={cutoffTime}
                  format="hh:mm a"
                  disableClock
                  className="time-picker"
                />
              </div>
            </Form.Group>
            <Form.Group controlId="control-1">
              <Form.Label>Capacity</Form.Label>
              <Form.Control
                type="string"
                name="capacity"
                placeholder="Enter capacity"
                value={capacity}
                onChange={(event) => setCapacity(event.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="control-2">
              <Form.Label>Max List Size Submission</Form.Label>
              <Form.Control
                type="string"
                name="maxListSize"
                placeholder="Enter max list size"
                value={maxListSize}
                onChange={(event) => setMaxListSize(event.target.value)}
              />
            </Form.Group>
            {/* <Form.Group controlId="control-3">
              <Form.Label>Booth Booking</Form.Label>
              <Form.Control
                as="select"
                name="hasBoothBooking"
                value={hasBoothBooking}
                onChange={(event) => setHasBoothBooking(event.target.value)}
                disabled
              >
                <option>No</option>
                <option>Yes</option>
              </Form.Control>
              <Form.Text className="text-muted">Booth Booking feature coming soon!</Form.Text>
            </Form.Group> */}
            <Button variant="primary" type="submit" className="mt-2">
              Save Changes
            </Button>
          </Form>
        </Col>
      </Container>
    </div>
  );
}

export default BotSettings;
