import React, { useCallback, useState } from 'react';
import { Container, Form, Button, Table, Row } from 'react-bootstrap';
import { getBar, modifyBar } from '../api';
import { convert24HourToMin, convertMinTo24Hour } from '../utils/timeConverter';
import { useParams } from 'react-router-dom';

const BarSettings = ({
  barHours,
  barIsActive,
  updateBar,
  handleChangeBar,
  barsByBusiness,
  selectedBarId,
}: any) => {
  const [hours, setHours] = useState(barHours);
  const [isActive, setIsActive] = useState(barIsActive);
  const params: any = useParams();
  handleChangeBar(params.barId);
  const weekKeys: any = {
    0: 'Monday',
    1: 'Tuesday',
    2: 'Wednesday',
    3: 'Thursday',
    4: 'Friday',
    5: 'Saturday',
    6: 'Sunday',
  };

  const handleChange = useCallback(
    (event: any) => {
      const [i, fieldName] = event.target.name.split('-');
      const fieldVal = event.target.value;
      const updatedHours = JSON.parse(JSON.stringify(hours));
      updatedHours[weekKeys[i]][fieldName] = fieldVal;
      setHours(updatedHours);
    },
    [hours, setHours]
  );

  const handleToggle = useCallback(
    (event: any) => {
      const [i, fieldName] = event.target.name.split('-');
      const updatedHours = JSON.parse(JSON.stringify(hours));
      updatedHours[weekKeys[i]].isOpen = !updatedHours[weekKeys[i]].isOpen;
      setHours(updatedHours);
    },
    [hours, setHours]
  );
  const barName = barsByBusiness.find((bar: any) => bar._id === selectedBarId)?.name;
  const handleSubmit = useCallback(() => {
    const hoursBody: any = [];
    console.log({ hours: hours }, 'hoursssss');
    // hours.forEach((elem: any, i: number) => {
    //   const open = elem.checked ? convert24HourToMin(elem.open) : 0;
    //   const closed = elem.checked ? convert24HourToMin(elem.closed) : 0;

    //   hoursBody.push({
    //     day: elem.day,
    //     open,
    //     closed,
    //   });
    // });
    updateBar({ hours });
  }, [hours, updateBar]);

  const handleToggleIsActive = useCallback(() => {
    updateBar({
      isActive: !isActive,
    });
    setIsActive(!isActive);
  }, [isActive, setIsActive]);

  return (
    <div>
      <Container className="full-height">
        <h2 className="status-title mt-4">Bar Settings: {barName}</h2>
        <p className="text-muted">
          Use the table below to set your operating hours. The chatbot will only accept reservations
          during operational hours.
        </p>
        <Row style={{ margin: 0 }}>
          <Form.Check
            type="checkbox"
            name={`isActive-toggle`}
            checked={isActive}
            onChange={handleToggleIsActive}
          />
          <p>Toggle if bar is active</p>
        </Row>
        <hr className="hr-break mt-3"></hr>
        <Table striped bordered hover size="sm" className="mt-4">
          <thead>
            <tr>
              <th>Operational</th>
              <th>Day</th>
              <th>From</th>
              <th>To</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(hours).map((elem: any, i: number) => {
              return (
                <tr key={i}>
                  <td>
                    <Form.Check
                      type="checkbox"
                      name={`${i}-checked`}
                      checked={hours[elem].isOpen}
                      onChange={handleToggle}
                    />
                  </td>
                  <td>{elem}</td>
                  <td>
                    <Form.Control
                      type="time"
                      name={`${i}-start`}
                      value={hours[elem].start}
                      onChange={handleChange}
                      disabled={hours[weekKeys[i]].isOpen ? false : true}
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="time"
                      name={`${i}-end`}
                      value={hours[elem].end}
                      onChange={handleChange}
                      disabled={hours[weekKeys[i]].isOpen ? false : true}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button variant="primary" type="submit" className="mt-1" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Container>
    </div>
  );
};

export default BarSettings;
