import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Socket from 'socket.io-client';
import { createReservation, ENDPOINT, modifyReservation } from '../api';

class EditorModal extends React.Component<any, any> {
  state = {
    show: false,
    name: '',
    date: '',
    guests: '',
    type: 'managerlist',
    isEditing: false,
  };

  componentDidMount() {
    const { reservation } = this.props;
    if (reservation) {
      this.setState({
        name: reservation.name,
        date: reservation.date,
        guests: reservation.guests,
        type: reservation.type,
        isEditing: true,
      });
    }
  }

  handleChange = (event: any) => {
    const fieldName = event.target.name;
    const fieldVal = event.target.value;
    this.setState({ [fieldName]: fieldVal });
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    const reservation = {
      name: this.state.name,
      date: this.state.date,
      guests: this.state.guests,
      type: this.state.type,
    };

    if (Number(this.state.guests) <= 0) {
      toast.error('Number of guests must be higher than 0.');
      return;
    }

    if (this.state.isEditing) {
      modifyReservation(this.props.reservation._id, reservation)
        .then((res: any) => {
          toast.success('Reservation was modified.');
          this.handleClose();
        })
        .catch((err: any) => {
          alert(err);
        });
    } else {
      this.props.makeReservation(reservation).then(() => {
        toast.success('Reservation was created.');
        this.handleClose();
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  public render() {
    return (
      <Modal animation={false} show={this.state.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {this.state.isEditing ? 'Modify Existing Reservation' : 'Create New Reservation'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Form.Group controlId="control-0">
              <Form.Label>Guest Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter name for reservation ex. John Doe"
                value={this.state.name}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="control-1">
              <Form.Label>Reservation Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={this.state.date}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group controlId="control-2">
              <Form.Label>Number of Guests</Form.Label>
              <Form.Control
                type="number"
                name="guests"
                min="1"
                placeholder="Enter number of guests"
                value={this.state.guests}
                onChange={this.handleChange}
              />
            </Form.Group>
            {!this.state.isEditing && (
              <Form.Text className="text-muted">
                Reservations created here will be under Manager Lists.
              </Form.Text>
            )}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={this.handleSubmit}>
            {this.state.isEditing ? 'Modify' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default EditorModal;
