import { Route, Routes, useParams } from 'react-router-dom';
import QueuePage from './QueuePage';
import Dashboard from './Dashboard';
import BotSettings from './BotSettings';
import AccountSettings from './AccountSettings';
import BarSettings from './BarSettings';
import { useEffect } from 'react';

const PrivateRouteBarWrapper = (props: any) => {
  const { barId } = useParams();

  useEffect(() => {
    if (barId && props.handleChangeBar) {
      props.handleChangeBar(barId);
    }
  }, [barId, props.handleChangeBar]);

  return (
    <Routes>
      <Route path={`/`} element={<Dashboard {...props} />} />
      <Route path={`/queue`} element={<QueuePage {...props} />} />
      <Route path={`/botsettings`} element={<BotSettings {...props} />} />
      <Route path={`/accountsettings`} element={<AccountSettings />} />
      <Route path={`/barsettings`} element={<BarSettings {...props} />} />
    </Routes>
  );
};

export default PrivateRouteBarWrapper;
