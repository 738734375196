import React, { useCallback, useState } from 'react';
import { forgotPassword } from '../api';
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import Logo from '../images/bargoat-app-icon.png';
import Lottie from 'react-lottie';
import animationData from '../lottie/drink.json';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);

  const sendEmail = useCallback(
    (e: any) => {
      e.preventDefault();

      forgotPassword({ email })
        .then(() => {
          setIsSent(true);
        })
        .catch((err: any) => {
          alert(err);
        });
    },
    [email, setIsSent]
  );
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Container fluid>
      <Row>
        <Col lg="6" className="split-left d-flex align-items-center">
          <div className="col-lg-7" id="form-login">
            <h2 className="form-heading">
              RESET YOUR PASSWORD{' '}
              <img src={Logo} alt="BarGoat" style={{ maxWidth: '36px' }} className="ml-1" />
            </h2>
            <hr className="form-split mt-2 mb-4"></hr>
            {isSent ? (
              <div>Password has been reset.</div>
            ) : (
              <Form>
                <FormGroup controlId="email">
                  <FormLabel>Email</FormLabel>
                  <FormControl
                    autoFocus
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  ></FormControl>
                </FormGroup>

                <Button disabled={email === ''} type="submit" onClick={sendEmail}>
                  Send
                </Button>
              </Form>
            )}
            <hr className="form-split mt-4 mb-3"></hr>
            <p className="help-text">
              Need assistance? <Link to="/#">Contact support</Link>
            </p>
          </div>
        </Col>
        <Col lg="6" className="split-right d-none d-lg-block">
          <div className="align-items-center" style={{ marginTop: '100px' }}>
            <Lottie options={defaultOptions} height={500} width={500} />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
