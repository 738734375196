import React from 'react';
import './../styles/InfoIcon.scss';
import { Container, Row, Col } from 'react-bootstrap';

class InfoIcon extends React.Component<any> {
  render() {
    return (
      <Container className="InfoIcon">
        <Row className="InfoIcon-Row-1"></Row>
        <Row className="InfoIcon-Row-2">
          <Col>{this.props.value}</Col>
        </Row>
        <Row className="InfoIcon-Row-3">
          <Col>{this.props.title}</Col>
        </Row>
      </Container>
    );
  }
}

export default InfoIcon;
