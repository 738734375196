import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import List from './List';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { convertMinToTime } from '../utils/timeConverter';
import { fuzzyMatch } from '../utils/fuzzyMatch';
import AppContext from '../contexts/AppContext';
import InfoIcon from './InfoIcon';
import { useParams } from 'react-router-dom';

function QueuePage({
  guestlist,
  queueGuestlist,
  queueGuestlistCount,
  confirmedGuestlistCount,
  guestlistCapacityCount,
  guestlistCapacityMax,
  guestlistCutoffTime,
  barsByBusiness,
}: any) {
  const params: any = useParams();
  const appContext = useContext(AppContext);
  const [filteredGuestlist, setFilteredGuestlist] = useState(guestlist);
  const [filteredQueueGuestlist, setFilteredQueueGuestlist] = useState(queueGuestlist);

  const reservationLists = useMemo(
    () => [
      {
        name: 'Held In Queue',
        reservations: filteredQueueGuestlist,
      },
      {
        name: 'Pushed Guestlists',
        reservations: filteredGuestlist,
      },
    ],
    [filteredGuestlist]
  );

  const handleChangeQuery = (query: string) => {
    const newQueueFilteredGuestlist = queueGuestlist.filter((element: any) => {
      return fuzzyMatch(query, element.name);
    });
    setFilteredQueueGuestlist(newQueueFilteredGuestlist);

    const newFilteredGuestlist = guestlist.filter((element: any) => {
      return fuzzyMatch(query, element.name);
    });
    setFilteredGuestlist(newFilteredGuestlist);
  };

  // handleSelection(reservation: any) {
  //   let toGuestlist: any[] = this.state.toGuestlist;
  //   const index = toGuestlist.findIndex((r) => r === reservation);
  //   if (index !== -1) toGuestlist.splice(index, 1);
  //   else toGuestlist.push(reservation);

  //   this.setState({ toGuestlist });
  // }

  // handleSubmit = async () => {
  //   for (let i = 0; i < this.state.toGuestlist.length; i++) {
  //     let reservation: any = this.state.toGuestlist[i];
  //     try {
  //       const bar = await getBar("5e501aaf334d4d5ea78c81b5");
  //       sendPushNotification(reservation.fb_id, {
  //         text: `Hey, you have been moved from waitlist to guestlist at ${bar.name} with ${reservation.guests} on ${reservation.date}. Hope to see you then!`,
  //         quick_replies: [
  //           {
  //             content_type: "text",
  //             title: "Get Started",
  //             payload: "GET_STARTED",
  //           },
  //         ],
  //       });
  //       await modifyReservation(reservation._id, {
  //         type: "guestlist",
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  //   alert("Selected reservations have been pushed to Guestlist.");
  //   this.getData();
  // };

  useEffect(() => {
    appContext.handleChangeQuery = handleChangeQuery;
  }, [appContext.handleChangeQuery, handleChangeQuery]);

  return (
    <Container style={{ marginTop: '1rem' }}>
      {/* Begin Status Components */}
      <Row>
        <Col lg="12">
          <h3 className="bar-name">
            {barsByBusiness.find((bar: any) => bar._id === params.barId)?.name}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Row className="status-row">
            <Col lg="6">
              <h2 className="status-title">Queue Status</h2>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <hr className="hr-break"></hr>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InfoIcon title="Held In Queue" value={queueGuestlistCount}></InfoIcon>
            </Col>
            <Col lg="6">
              <InfoIcon title="Pushed Guestlists" value={confirmedGuestlistCount}></InfoIcon>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row className="status-row">
            <Col lg="6">
              <h2 className="status-title">Bot Variables</h2>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <hr className="hr-break"></hr>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <InfoIcon
                title="Guestlist Capacity"
                value={`${guestlistCapacityCount}/${guestlistCapacityMax}`}
              ></InfoIcon>
            </Col>
            <Col lg="6">
              <InfoIcon
                title="Guestlist Cutoff Time"
                value={convertMinToTime(guestlistCutoffTime)}
              ></InfoIcon>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* End Status Components */}

      {/* Begin List Components */}
      <Row>
        {reservationLists.map((reservationList, index) => (
          <Col lg="4" key={index}>
            <List
              key={index}
              reservations={reservationList.reservations}
              name={reservationList.name}
            ></List>
          </Col>
        ))}
      </Row>
      {/* End List Components */}
    </Container>
  );
}

export default QueuePage;
