import React from 'react';
import { Container, Form, Button, Col } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import { getUser, modifyUser } from '../api';
import { Link } from 'react-router-dom';

const cookies = new Cookies();

class AccountSettings extends React.Component {
  // intervalID: any;
  state = {
    name: '',
    username: '',
    // oldPassword: "",
    newPassword: '',
  };

  componentDidMount() {
    this.getData();
    // this.intervalID = setTimeout(this.getData.bind(this), 5000);
  }

  // componentWillUnmount() {
  //   clearTimeout(this.intervalID);
  // }

  getData() {
    getUser(cookies.get('tk'))
      .then((res: any) => {
        this.setState({ name: res.name, username: res.username });
      })
      .catch((err: any) => console.log(err));
  }

  handleChange = (event: any) => {
    const fieldName = event.target.name;
    const fieldVal = event.target.value;
    this.setState({ [fieldName]: fieldVal });
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    const body = {
      password: this.state.newPassword,
    };
    modifyUser(cookies.get('tk'), body)
      .then((res: any) => {
        alert('Saved');
      })
      .catch((err: any) => console.log(err));
  };

  render() {
    return (
      <div>
        <Container>
          <Col lg={4}>
            <h2 className="status-title mt-4">Account Settings</h2>
            <hr className="hr-break mt-3"></hr>
            <Form onSubmit={this.handleSubmit} className="mt-1">
              <Form.Group controlId="control-0">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" name="name" value={this.state.name} disabled />
              </Form.Group>
              <Form.Group controlId="control-1">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" name="username" value={this.state.username} disabled />
                <Form.Text className="text-muted">
                  <Link to="#">Contact us</Link> to make changes to your account username.
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="control-2">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="newPassword"
                  placeholder="Enter new password"
                  value={this.state.newPassword}
                  onChange={this.handleChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-2">
                Save Changes
              </Button>
            </Form>
          </Col>
        </Container>
      </div>
    );
  }
}

export default AccountSettings;
