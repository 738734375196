import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  ENDPOINT,
  createReservation,
  getBar,
  getBarsByBusiness,
  getBusiness,
  getReservations,
  modifyBar,
  modifyReservation,
} from '../api';
import { convertMinTo24Hour, toDateString } from '../utils/timeConverter';
import socketIOClient from 'socket.io-client';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
let interval: number;
const withData = (WrappedComponent: any, ...props: any) => {
  const ComponentWithData = () => {
    const defaultSelectedDate = useMemo(() => {
      const local = window.localStorage.getItem('selectedDate');
      return local ? new Date(local) : new Date();
    }, []);
    const [selectedDate, setSelectedDate] = useState(defaultSelectedDate);
    const [guestlist, setGuestlist] = useState<Array<any>>([]);
    const [confirmedGuestlistCount, setConfirmedGuestlistCount] = useState(0);
    const [managerList, setManagerList] = useState([]);
    const [taggedList, setTaggedList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [queueGuestlist, setQueueGuestlist] = useState([]);
    const [queueGuestlistCount, setQueueGuestlistCount] = useState(0);
    const [checkinsCount, setCheckinsCount] = useState(0);
    const [guestlistCapacityCount, setGuestlistCapacityCount] = useState(0);
    const [guestlistCapacityMax, setGuestlistCapacityMax] = useState(0);
    const [guestlistCutoffTime, setGuestlistCutoffTime] = useState(0);
    const [maxListSizeSubmission, setMaxListSizeSubmission] = useState(0);
    const [barHours, setBarHours] = useState([]);
    const [barIsActive, setBarIsActive] = useState(false);
    const [name, setName] = useState('');
    const [guests, setGuests] = useState(0);
    const [businessId, setBusinessId] = useState(cookies.get('business_id'));
    const [businessName, setBusinessName] = useState('');
    const [barsByBusiness, setBarsByBusiness] = useState<any[]>([]);
    const [selectedBarId, setSelectedBarId] = useState('');

    const socket = useMemo(() => socketIOClient(ENDPOINT), []);

    const _refreshCounts = useCallback(
      (guestlist: any, taggedList: any) => {
        let guestlistCapacityCount = 0,
          confirmedGuestlistCount = 0,
          checkinsCount = 0;
        guestlist.forEach((reservation: any) => {
          guestlistCapacityCount += reservation.guests;
          if (reservation.isConfirmed) confirmedGuestlistCount += 1;
          if (reservation.isPresent) checkinsCount += 1;
        });

        taggedList.forEach((reservation: any) => {
          guestlistCapacityCount += reservation.guests;
          if (reservation.isConfirmed) confirmedGuestlistCount += 1;
          if (reservation.isPresent) checkinsCount += 1;
        });

        setConfirmedGuestlistCount(confirmedGuestlistCount);
        setCheckinsCount(checkinsCount);
        setGuestlistCapacityCount(guestlistCapacityCount);
      },
      [setConfirmedGuestlistCount, setCheckinsCount, setGuestlistCapacityCount]
    );

    const _setGuestlistData = useCallback(
      (guestlist: any, managerList: any, taggedList: any, queueGuestlist: any) => {
        setGuestlist(guestlist);
        setManagerList(managerList);
        setTaggedList(taggedList);
        setQueueGuestlist(queueGuestlist);
        setQueueGuestlistCount(queueGuestlist.length);
      },
      [setGuestlist, setManagerList, setQueueGuestlist, setQueueGuestlistCount]
    );

    const _transformBarHours = useCallback(
      (hours: any) => {
        const barHours: any = hours[0];
        // hours.forEach((elem: any) => {
        //   barHours.push({
        //     checked: elem.open !== 0 && elem.closed !== 0,
        //     open: convertMinTo24Hour(elem.open),
        //     closed: convertMinTo24Hour(elem.closed),
        //     day: elem.day,
        //   });
        // });

        setBarHours(hours);
      },
      [setBarHours]
    );

    const _setBarData = useCallback(
      (barResponse: any) => {
        _transformBarHours(barResponse.hours);
        setGuestlistCapacityMax(barResponse.rules.capacity);
        setGuestlistCutoffTime(barResponse.rules.cutoffTime);
        setMaxListSizeSubmission(barResponse.rules.maxListSizeSubmission);
        setBarIsActive(barResponse.isActive);
      },
      [
        _transformBarHours,
        setGuestlistCapacityMax,
        setGuestlistCutoffTime,
        setMaxListSizeSubmission,
      ]
    );

    const makeReservation = useCallback(
      async (reservation: any) => {
        try {
          await createReservation({ ...reservation, bar_id: selectedBarId })
            .then(() => {
              _fetchReservations();
              socket.emit('makeReservation');
            })
            .catch((err) => {
              console.log('Error creating reservation data:', err);
              toast.error('Failed to create reservation.');
            });
        } catch (err) {
          console.log('Error creating reservation data:', err);
        }
      },
      [name, selectedDate, selectedBarId, guests]
    );

    const _fetchReservations = useCallback(async () => {
      try {
        if (!selectedBarId) {
          _setGuestlistData([], [], [], []);
          _refreshCounts([], []);
        } else {
          const date = toDateString(selectedDate);
          const guestlist = await getReservations({
            bar_id: selectedBarId,
            type: 'guestlist',
            date,
          });

          const managerList = await getReservations({
            bar_id: selectedBarId,
            type: 'managerlist',
            date,
          });

          const taggedList = [guestlist, managerList].filter(
            (reservation) => reservation.tags && reservation.tags.length > 0
          );

          const queueGuestlist = await getReservations({
            bar_id: selectedBarId,
            type: 'qguestlist',
            date,
          });

          _setGuestlistData(guestlist, managerList, taggedList, queueGuestlist);
          _refreshCounts(guestlist, taggedList);
        }
      } catch (err) {
        setLoading(false);
        console.log('Error fetching reservation data:', err);
      }
    }, [selectedDate, selectedBarId, _setGuestlistData, _refreshCounts]);

    const _fetchBarsByBusiness = useCallback(async () => {
      try {
        const barsResponse = await getBarsByBusiness(businessId);
        if (!selectedBarId) {
          const barId = barsResponse[0]._id;
          setSelectedBarId(barId);
        }
        setBarsByBusiness(barsResponse);
      } catch (err) {
        setLoading(false);
        console.log('Error fetching bar data:', err);
      }
    }, [selectedBarId, setSelectedBarId, setBarsByBusiness]);

    const _fetchBusiness = useCallback(async () => {
      try {
        setLoading(true);
        const business = await getBusiness(businessId);
        setBusinessName(business.name);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log('Error fetching business:', err);
      }
    }, [businessId, setBusinessName]);

    const _fetchBar = useCallback(async () => {
      if (!selectedBarId) return; // TODO: fix multiple calls with no selectedBarId

      try {
        const barResponse = await getBar(selectedBarId);
        _setBarData(barResponse);
      } catch (err) {
        setLoading(false);
        console.log('Error fetching bar data:', err);
      }
    }, [selectedBarId, _setBarData]);

    const fetchData = useCallback(async () => {
      setLoading(true);
      _fetchBarsByBusiness();
      _fetchBar();
      _fetchReservations();
      setLoading(false);
    }, [_fetchReservations, _fetchBar]);

    const refreshReservations = useCallback(async () => {
      setLoading(true);
      _fetchReservations();
      setLoading(false);
    }, [_fetchReservations]);

    if (interval) {
      window.clearInterval(interval);
    }
    // interval = window.setInterval(refreshReservations, 20000);

    const handleChangeDate = useCallback(
      (date: Date) => {
        window.localStorage.setItem('selectedDate', date.toString());
        setSelectedDate(date);
      },
      [setSelectedDate]
    );

    const handleChangeBar = useCallback(
      (barId: string) => {
        setSelectedBarId(barId);
      },
      [setSelectedBarId]
    );

    const updateReservation = useCallback(
      async (modifiedReservation: any) => {
        const guestlistByType =
          modifiedReservation.type === 'qguestlist' ? queueGuestlist : guestlist;
        const index = guestlistByType.indexOf((x: any) => x._id === modifiedReservation._id);
        guestlistByType[index] = modifiedReservation;
        _refreshCounts(guestlistByType, []);
        modifyReservation(modifiedReservation._id, {
          ...modifiedReservation,
        })
          .then(() => {
            _fetchReservations();
            setLoading(false);
            toast.success('Reservation updated successfully.');
            socket.emit('updateReservation');
          })
          .catch(() => {
            setLoading(false);
            toast.error('Failed to update reservation.');
          });
      },
      [_fetchReservations, _refreshCounts]
    );

    const moveToTagged = useCallback(
      async (modifiedReservation: any) => {
        modifyReservation(modifiedReservation._id, {
          ...modifiedReservation,
          tags: [...modifiedReservation.tags, 'tag'],
        })
          .then(() => {
            _fetchReservations();
            setLoading(false);
            socket.emit('updateReservation');
          })
          .catch(() => {
            setLoading(false);
            toast.error('Failed to update reservation.');
          });
      },
      [_fetchReservations, _refreshCounts]
    );

    const moveFromTagged = useCallback(
      async (modifiedReservation: any) => {
        modifyReservation(modifiedReservation._id, {
          ...modifiedReservation,
          tags: modifiedReservation.tags.filter((tag: string) => tag !== 'tag'),
        })
          .then(() => {
            _fetchReservations();
            setLoading(false);
            socket.emit('updateReservation');
          })
          .catch(() => {
            setLoading(false);
            toast.error('Failed to update reservation.');
          });
      },
      [_fetchReservations, _refreshCounts]
    );

    const updateBar = useCallback(
      (body: any) => {
        setLoading(true);

        modifyBar(selectedBarId, body)
          .then((response) => {
            toast.success('Saved settings.');
            setLoading(false);
            _setBarData(response);
          })
          .catch(() => {
            setLoading(false);
            toast.error('Failed saving settings.');
          });
      },
      [selectedBarId]
    );

    useEffect(() => {
      _fetchBusiness();
    }, []);

    useEffect(() => {
      fetchData();
    }, [selectedDate]);

    useEffect(() => {
      _fetchBar();
      _fetchReservations();
    }, [selectedBarId]);

    useEffect(() => {
      socket.off('reservationUpdated');
      socket.on('reservationUpdated', () => {
        _fetchReservations();
      });
    }, [_fetchReservations]);

    useEffect(() => {
      socket.off('barUpdated');
      socket.on('barUpdated', (response) => {
        _setBarData(response);
      });
    }, [_setBarData]);

    useEffect(() => {
      return () => {
        socket.disconnect();
      };
    }, []);

    return (
      <WrappedComponent
        selectedDate={selectedDate}
        guestlist={guestlist}
        makeReservation={makeReservation}
        confirmedGuestlistCount={confirmedGuestlistCount}
        managerList={managerList}
        taggedList={taggedList}
        queueGuestlist={queueGuestlist}
        queueGuestlistCount={queueGuestlistCount}
        checkinsCount={checkinsCount}
        guestlistCapacityCount={guestlistCapacityCount}
        guestlistCapacityMax={guestlistCapacityMax}
        guestlistCutoffTime={guestlistCutoffTime}
        maxListSizeSubmission={maxListSizeSubmission}
        barHours={barHours}
        barIsActive={barIsActive}
        refreshData={fetchData}
        refreshReservations={refreshReservations}
        handleChangeDate={handleChangeDate}
        updateReservation={updateReservation}
        moveToTagged={moveToTagged}
        moveFromTagged={moveFromTagged}
        updateBar={updateBar}
        barsByBusiness={barsByBusiness}
        handleChangeBar={handleChangeBar}
        selectedBarId={selectedBarId}
        businessName={businessName}
        loading={loading}
        {...props}
      />
    );
  };

  return ComponentWithData;
};

export default withData;
