import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'qrcode';

const QRCodePage = () => {
  const [qrcode, setQrcode] = useState('');

  useEffect(() => {
    QRCode.toDataURL('https://bargoat.com', (err, url) => {
      if (err) return console.log(err);

      setQrcode(url);
    });
  });

  return (
    <div>
      <img src={qrcode} />
    </div>
  );
};

export default QRCodePage;
